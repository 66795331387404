// src/components/Home.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// @ts-ignore
import vedioHighlight from '../assets/home_highlight.mp4';
import ContactUs from "./ContactUs";

const Home: React.FC = () => {
    return (
        <Container >
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <video autoPlay loop muted style={{ maxWidth: '100%', height: 'auto' }}>
                    <source src={vedioHighlight} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <ContactUs />
        </Container>
    );
};

export default Home;
