// src/components/Header.tsx
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar>
                <Link to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>
                    <Typography variant="h4" component="div" sx={{ color: '#fff' }}>
                        DoorA
                    </Typography>
                </Link>
                <div style={{ marginLeft: 'auto' }}>
                    <Link to="/contact" style={{ color: '#fff', textDecoration: 'none', marginLeft: '20px' }}>CONTACT US</Link>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
