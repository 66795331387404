// src/components/Footer.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Footer: React.FC = () => {
    return (
        <footer style={{ backgroundColor: '#000', color: '#fff', padding: '20px 0', textAlign: 'center' }}>
            <Container>
                <div style={{ marginBottom: '20px' }}>
                    <Typography variant="body1">
                        Doora Services Private Limited
                    </Typography>
                    <Typography variant="body2">
                        CIN : U62099TN2024PTC168008
                    </Typography>
                    <Typography variant="body2">
                        10/50B Kunnamparai, Kattathurai, Kanyakumari District, Tamilnadu - 629158
                    </Typography>
                </div>
                <Typography variant="body2">
                    © 2024 Doora Services Private Limited. All rights reserved.
                </Typography>
            </Container>
        </footer>
    );
};

export default Footer;
